/* NAVBAR------------------------------------------------------------------------*/
#root {
    --black: #141414;
    --yellow: #EEC643;
    --white: #EEF0F2;
    --blue: #0D21A1;
    --darkBlue: #011638;
}

nav {
    background-color: var(--darkBlue);
    display: flex;
    list-style: none;
    border-bottom: solid var(--white) 1px;
    overflow: hidden;
}

 #nav-links {
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 60%;
}


 .nav-item {
    color: var(--white);
    padding: 1rem 1rem;
    text-decoration: none;
    font-size: 17px;
}

#hamburger {
    display: block;
}

.hamburgerContainer {
    background: var(--yellow);
}

.hamburgerContainer {
    cursor: pointer;
}

.bar1,
.bar2,
.bar3 {
    width: 2rem;
    height: 5px;
    background-color: var(--white);
    margin: 6px 0;
    transition: 0.4s;
}

/* Rotate first bar */
.change .bar1 {
    transform: translate(0, 11px) rotate(-45deg);
}

/* Fade out the second bar */
.change .bar2 {
    opacity: 0;
}

/* Rotate last bar */
.change .bar3 {
    transform: translate(0, -11px) rotate(45deg);

}

.nav-link:visited {
    text-decoration: none;
    color: var(--white);
}

.navbar-brand:visited {
    text-decoration: none;
    color: var(--yellow);
}

.nav-link:hover {
    color: var(--yellow);
}

.navbar-brand {
    margin-left: 5%;
    color: var(--yellow);
}

.navbar-brand:hover {
    color: var(--white);
}

.navbar-right {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.navbar-left {
    display: flex;
    flex-direction: row;
    margin-right: 3%;

}

.nav-item {
    display: block;
    margin-left: 1rem;
}

.nav-link {
    color: var(--white);
    display: flex;

}

@media (max-width: 769px ) {
    .nav-item {
        font-size: smaller;
    }
}

@media (max-width: 450px ) {
#nav-links {
    width: auto;
    font-size: small;
}

}

@media (max-width: 330px) {
.bar1,
.bar2,
.bar3 {
    width: 20px;
    height: 5px;
    margin: 6px 0;
}

}
