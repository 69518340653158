#root {
    --black: #141414;
    --yellow: #EEC643;
    --white: #EEF0F2;
    --blue: #0D21A1;
    --darkBlue: #011638;
}

.priceMain {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
}

.priceCheckContainer {
    margin-top: 10vh;
    color: var(--white);
    margin-bottom: 10vh;
}

.priceCheckTitle {
font-size: 10vh;
}

.priceContainer {
margin: 1rem;
background-image: url("../../img/wood_board.jpg");
background-attachment: fixed;
background-size: cover;
border: var(--yellow) solid 2px;
}

.priceWrapper {
    background: var(--yellow);
    margin: 1rem;
    border-radius: 5px;
    border: var(--darkBlue) solid 2px;
}


.costTitle{
font-size: 5vh;
color: var(--white);
text-decoration: underline;

}

.price {
font-size: 10vh;
}

.priceItems {
    border: 2px solid var(--yellow);
    padding: 5%;
    display: grid;
    grid-template-rows: repeat(1);
    justify-content: space-around;
    align-items: center;
    background-color: var(--blue);
}
