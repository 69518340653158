/* HOME PAGE__________________________________________________________*/
#root {
    --black: #141414;
    --yellow: #EEC643;
    --white: #EEF0F2;
    --blue: #0D21A1;
    --darkBlue: #011638;
}

html {
    width: 100%;
    height: 100%;
   background-image: url(../../img/modernshop.jpeg);
   background-position: center;
   background-size: cover;
}

.homeContainer {
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
}

.contentContainer {
    padding-bottom: 60px;
    color: var(--black);
    margin-top: 1%;
    padding: 3%;
    font-size: 32px;
}

.homeSkillsContainer{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 20rem;
}

.homeSkill{
    position: relative;
    display: flex;
    color: var(--white);
    justify-content: center;
    align-items: center;
    background-color: var(--darkBlue);
    border-radius: 20px;
    width: 15rem;
    height: 15rem;
    border: solid var(--yellow) 1px;
    box-shadow: 10px 5px 5px var(--yellow);
}

.homeSkillIcon{
    position: absolute;
    top: -20%;
    left: 35%;
    border-radius: 100px;
    background-color: var(--white);
    width: 5rem;
    height: 5rem;
    border: solid var(--yellow) 1px;
}

.skillIcon {
    position: absolute;
    color: var(--yellow);
    left: 30%;
    bottom: 30%;
}



.adContainer {
    display: flex;
    background-color: var(--darkBlue);
    padding: 1%;
    align-items: center;
    border: var(--black) solid 5px;
    margin-top: 10%;
    margin-bottom: 10%;
    position: relative;
    box-shadow: 10px 5px 5px var(--yellow);
}

.adLink {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.adImage {
    border: var(--black) solid 2px;
    object-fit: cover; 
}

.adText {
    color: var(--white);
    width: 100%;
    left: 0;
    top: 15%;
}


.homeMain {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 2%;
    color: var(--black);
    padding: 3%;
    font-size: 32px;
}

.homeMainItemContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url(../../img/wooden-board-h.jpeg);
    border-radius: 10px;
    padding: 1rem;
    box-shadow: 10px 5px 5px var(--yellow);
}

a {
    text-decoration: none;
}

.homeMainItem {
    background-image: url(../../img/shutterstock_79257886.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 15rem;
    height: 5rem;
    padding: 1%;
    color: var(--black);
    text-decoration: none;
}

.smallText {
    font-size: smaller;
}


.homeIntro {
    display: flex;
    flex-direction: column;
    justify-content: left;
    font-size: 70%;
    background: var(--darkBlue);
    color: var(--white);
    margin-top: 10%;
    margin-bottom: 10%;
    left: 0px;
    border-radius: 15px;
    padding: 5%;
    border: solid var(--black) 2px;
    box-shadow: 10px -20px 5px var(--yellow);

}

.pageDivider1 {
    position: absolute;
    left: 0;
    z-index: -1;
    height: 125vh;
    width: 100%;
    background-color: var(--black);
    clip-path: polygon(0 50%, 100% 0, 100% calc(100% - 7vw), 0% 100%); 
}

.pageDivider2 {
    position: absolute;
    left: 0;
    z-index: -1;
    height: 100vh;
    width: 100%;
    background-color: var(--black);
    clip-path: polygon(0 50%, 100% 0, 100% calc(100% - 7vw), 0% 0);
}

.pageDivider3 {
    position: absolute;
    left: 0;
    z-index: -1;
    height: 75vh;
    width: 100%;
    background-color: var(--black);
    clip-path: polygon(0 50%, 100% 0, 100% calc(100% - 7vw), 0% 100%);
}

/* Image Slider-----------------------------------------------------------------*/
.imgSliderContainer {
    margin-top: 5%;
    margin-bottom: 10%;
    background: var(--darkBlue);
    border: var(--black) solid 2px;
    border-radius: 10px;
    box-shadow: 10px 5px 5px var(--yellow);
}

/* MEDIA QUERIES------------------------------------------------------------------------- */
@media (max-width: 1075px) {
    .homeSkill {
        width: 10rem;
        height: 10rem;
        font-size: smaller;
    }

    .homeSkillIcon {
        width: 3rem;
        height: 3rem;
    }
    
     .skillIcon {
        width: 1.5rem;
        height: 1.5rem;
        left: 25%;
        bottom: 30%;
    }

    .homeSkillsContainer {
        height: 15rem;
    }

    footer {
        font-size: smaller;
    }
}

@media (max-width: 650px) {
    .homeMain {
        flex-direction: row;
        
    }
    .homeMainItemContainer{
        margin-top: 0;
        font-size: small;
    }
    .homeMainItem {
    width: 5rem;
    height: 2rem;
    }

    .homeSkillsContainer {
        height: 10rem;
    }
    
    .homeSkill {
        width: 6rem;
        height: 6rem;
        font-size: small;
    }

    .homeSkillIcon {
        width: 2.5rem;
        height: 2.5rem;
        top: -20%;
            left: 35%;
    }
    
     .skillIcon {
        width: 1rem;
        height: 1rem;
        left: 25%;
        bottom: 30%;
    }
}


@media (max-width: 480px) {

    .navbar-right {
        margin-right: 20px;
    }

    .sliderText {
        border-radius: 0px;
        text-align: center;
        width: auto;

    }

    footer {
        font-size: small;
    }

    .homeHeader {
        font-size: small;
    }

    .homeIntro {
        font-size: small;
    }

    .mainTitleContainer {
        margin-bottom: 10px;
    }

    .adContainer {
        font-size: small;
    }

    .buttons {
        font-size: 20px;
    }

}


@media (max-width: 473px) {

    .navbar {
        display: flex;
        justify-content: space-around;
    }


}

@media (max-width: 330px) {



    .homeSkill {
            width: 5rem;
            height: 5rem;
            font-size: 10px;
        }

    .homeSkillIcon {
            width: 2rem;
            height: 2rem;
        }
      
    .skillIcon {
        width: .9rem;
        height: .9rem;
    }
    
}