/* Boiler Plate */
#root {
  width: 100%;
  height: 100%;
  --black: #141414;
  --yellow: #EEC643;
  --white: #EEF0F2;
  --blue: #0D21A1;
  --darkBlue: #011638;
}

* {
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}

html {
  background: rgb(0, 0, 0);
  background: radial-gradient(circle, rgba(0, 0, 0, 1) 0%, rgba(121, 65, 9, 1) 74%, rgba(0, 0, 0, 1) 86%);
}

html,
body {
  min-height: 100%;
}


body {
  background: transparent;
  margin: 0;
  padding: 0;
  
}

.App {
  background: transparent;
  text-align: center;

}

.buttons{
  margin-bottom: 2px;
  border: var(--black) solid 2px;
  border-radius: 5px;
  background-color: var(--yellow);
  color: var(--darkBlue);
  padding: 3px;
  margin-left: 2px;
  margin-right: 2px;
}

.buttons:hover{
  text-decoration: none;
  color: var(--blue);
}

footer {
  display: flex;
  bottom: 0;
  width: 100%;
  height: 100px; 
  display: flex;
  /* Height of the footer */
  background-color: var(--darkBlue);
  color: var(--yellow);
  justify-content: space-around;
  align-items: center;
  font-size: 1.5rem;
  font-weight:bold;
  border-top: var(--black) solid 2px;
}

.supportText {
  margin-right: 5%;
}

.mainTitleContainer {
 margin: 1%;
  padding: 2%;
  background-image: url("../src/img/wood_board.jpg");
  background-attachment: fixed;
  background-size: cover;
  margin-bottom: 50px;
  border-radius: 10px;
  border: solid var(--black) 2px;

}

.mainTitle {
  padding: 2%;
 background-color: var(--yellow);
 border-radius: 10px;

}


/*---------------------LOGIN AND REGISTER STYLES -----------------------------*/
.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: var(--yellow);
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px var(--black)(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px var(--black)(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px var(--black)(0, 0, 0, 0.3);
}

.loginBtn,
 .signupBtn{
  margin-top: 2vh;
  background-color: var(--blue);
  border-color: var(--black);
  color: var(--white);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.col-md-12 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
}

/* MEDIA QUERIES------------------------------------------------------------------------- */
@media (max-width: 1075px) {
 

  footer{
    font-size: smaller;
  }
}



@media (max-width: 480px) {
  
  .navbar-right {
    margin-right: 20px;
  }

.buttons{
  font-size: 20px;
}

}


@media (max-width: 473px) {

  .navbar{
    display: flex;
    justify-content: space-around;
  }

  .supportText {
    font-size: 10px;
  }

}

